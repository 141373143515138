import { useEffect, useState } from "react";
import hostname from "./hostname";

export type DeviceSummary = {
    id: string,
    active_sessions: number,
};
export type Device = {
    id: string,
    active_sessions?: Session[],
    addresses?: Record<string, string[]>,
    addresses_updated?: string,
};
export type Session = {
    id: string,
}

export function useDevices() {
    const [data, setData] = useState<DeviceSummary[]>([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<any>(undefined);

    const refetch = () => {
        setLoading(true);
        const go = async () => {
            try {
                const response = await fetch(`${hostname.api}/devices`);
                const json = await response.json();
                setData(json.devices);
                setError(undefined);
            }
            catch (err) {
                setError(err);
            }
            finally {
                setLoading(false);
            }
        }
        go()
    };

    useEffect(() => {
        refetch()
    }, []);

    return { data, loading, error, refetch };
}

export function useDevice(id: string | undefined) {
    const [data, setData] = useState<Device | undefined>(undefined);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<any>("");

    useEffect(() => {
        if (!id) {
            return;
        }
        setLoading(true);
        const go = async () => {
            try {
                const response = await fetch(`${hostname.api}/devices/${id}`);
                const json = await response.json();
                setData(json);
            }
            catch (err) {
                setError(err);
            }
            finally {
                setLoading(false);
            }
        }
        go()
    }, [id]);

    const refetch = () => {
        if (!id) {
            return;
        }
        setLoading(true);
        const go = async () => {
            try {
                const response = await fetch(`${hostname.api}/devices/${id}`);
                const json = await response.json();
                setData(json);
            }
            catch (err) {
                setError(err);
            }
            finally {
                setLoading(false);
            }
        }
        go()
    };

    return { data, loading, error, refetch };
}

export function usePing(id: string | undefined) {
    const [data, setData] = useState<string | undefined>(undefined);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<any>(undefined);

    const refetch = () => {
        if (!id) {
            return;
        }
        setLoading(true);
        const go = async () => {
            try {
                const response = await fetch(`${hostname.api}/sessions/${id}/ping`, { method: 'POST' });
                const text = await response.text();
                setData(text);
                setError(undefined);
            }
            catch (err) {
                setError(err);
            }
            finally {
                setLoading(false);
            }
        }
        go()
    };

    return { data, loading, error, refetch };
}
