import './App.css';
import { Link, useParams } from "react-router-dom";
import { useDevice, Session, usePing } from './api';
import { useEffect } from 'react';
import Timestamp from './Timestamp';

const Device: React.FC<{}> = () => {
  const params = useParams();
  const id = params.deviceId;

  const { data, loading, error, refetch } = useDevice(id);

  useEffect(() => {
    const interval = setInterval(refetch, 5000);
    return () => clearInterval(interval);
  }, [refetch])

  return (
    <section>
      <header>
        <h1>{ id ?? "???"} {loading && <span>Loading...</span>}</h1>
      </header>
      <main>
        {error && <div>{error}</div>}
        <Sessions sessions={data?.active_sessions}/>
        <Addresses addresses={data?.addresses} addresses_updated={data?.addresses_updated}/>
      </main>
    </section>
  );
}

const Sessions: React.FC<{ sessions?: Session[] }> = ({ sessions }) => {
  return (
    <section>
      <header>
        <h2>Sessions</h2>
      </header>
      <main>
        <ul>
          {sessions && sessions.map((session) => (
            <li key={session.id}>{session.id} <Link to={session.id}>terminal</Link> <Ping sessionId={session.id}/></li>
          ))}
          {(!sessions || !sessions.length) && <li><em>No active sessions</em></li>}
        </ul>
      </main>
    </section>
  );
}

const Ping: React.FC<{ sessionId: string }> = ({ sessionId }) => {
  const { data, loading, error, refetch } = usePing(sessionId);

  return (
    <span>
      <button onClick={refetch}>Ping</button>
      {loading && "Pinging... "}
      {data && data}
      {error && error.toString()}
    </span>
  )
}

const Addresses: React.FC<{ addresses?: Record<string, string[]>, addresses_updated?: string }> = ({ addresses, addresses_updated }) => {
  return (
    <section>
      <header>
        <h2>Addresses</h2>
      </header>
      <main>
        <ul>
          {addresses && Object.keys(addresses).map((iface) => {
            const ips = addresses[iface];
            return (
              <li key={iface}>
                <h3>{iface}</h3>
                <ul>
                  {ips.map((ip) => (<li key={ip}>{ip}</li>))}
                </ul>
              </li>
            )
          })}
        </ul>
      </main>
      <footer>
        <p>Updated <Timestamp timestamp={addresses_updated}/></p>
      </footer>
    </section>
  );
}

export default Device;
