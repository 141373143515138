import { Link } from 'react-router-dom';
import './App.css';
import { useDevices } from './api';
import { useEffect } from 'react';

const Devices: React.FC<{}> = () => {
  const { data, loading, error, refetch } = useDevices();

  useEffect(() => {
    const interval = setInterval(refetch, 5000);
    return () => clearInterval(interval);
  }, [refetch])

  return (
    <section>
      <header>
        <h1>Devices {loading && <span>Loading...</span>}</h1>
      </header>
      <main>
        {error && <div>{error.toString()}</div>}
        <ul>
          {data && data.map((device) => (
            <li key={device.id} data-activity={device.active_sessions ? 'active' : 'inactive'} style={{fontWeight: device.active_sessions ? '700' : '400'}}>
              <Link to={`/devices/${device.id}`}>{device.id}</Link>
            </li>
          ))}
        </ul>
      </main>
    </section>
  );
}

export default Devices;
