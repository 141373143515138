const Timestamp: React.FC<{ timestamp?: string }> = ({ timestamp }) => {
    if (!timestamp) {
        return (<></>);
    }

    const d = new Date(timestamp);
    const rendered = d.toLocaleString();
    const now = new Date();
    const secondsAgo = (now.valueOf() - d.valueOf()) / 1000;
    let offset = "just now";
    if (secondsAgo > 86400) {
        let daysAgo = Math.floor(secondsAgo / 86400);
        let hoursAgo = Math.floor((secondsAgo - (daysAgo * 86400)) / 3600);
        offset = `${daysAgo} days, ${hoursAgo} hours ago`;
    } else if (secondsAgo > 3600) {
        let hoursAgo = Math.floor(secondsAgo / 3600);
        let minutesAgo = Math.floor((secondsAgo - (hoursAgo * 3600)) / 60);
        offset = `${hoursAgo} hours, ${minutesAgo} minutes ago`;
    } else if (secondsAgo > 60) {
        let minutesAgo = Math.floor(secondsAgo / 60);
        let partialSecondsAgo = Math.floor(secondsAgo - (minutesAgo * 60));
        offset = `${minutesAgo} minutes, ${partialSecondsAgo} seconds ago`;
    } else if (secondsAgo > 0) {
        let partialSecondsAgo = Math.floor(secondsAgo);
        offset = `${partialSecondsAgo} seconds ago`;
    }

    return (
        <time dateTime={timestamp}>{rendered} ({offset})</time>
    );
}

export default Timestamp;