import './App.css';
import { Link, useParams } from "react-router-dom";
import { Terminal } from 'xterm';
import { FitAddon } from 'xterm-addon-fit';
import 'xterm/css/xterm.css';
import { createRef, useEffect, useState } from 'react';
import hostname from "./hostname";

const Session: React.FC<{}> = () => {
  const params = useParams();
  const deviceId = params.deviceId;
  const sessionId = params.sessionId;
  const ref = createRef<HTMLDivElement>();
  const [open, setOpen] = useState(false);
  const [closed, setClosed] = useState(false);
  
  useEffect(() => {
    if (!ref.current) {
      return;
    }
    if (!sessionId) {
      return;
    }
    if (ref.current.classList.contains('open')) {
      // Can't figure out why this is running twice. So, use DOM to enforce that
      // it only runs once.
      return;
    }
    
    ref.current.classList.add('open');
    const term = new Terminal();
    const fitAddon = new FitAddon();
    term.loadAddon(fitAddon);
    term.open(ref.current);
    fitAddon.fit();

    const rows = term.rows;
    const cols = term.cols;

    const url = `${hostname.ws}/term/${sessionId}?rows=${rows}&cols=${cols}`;
    const ws = new WebSocket(url);

    ws.addEventListener('open', ev => {
      setOpen(true);
      term.onData(function(e) {
        ws.send(e)
      })
    });
    ws.addEventListener('message', async ev => {
      const buffer = await ev.data.arrayBuffer();
      const uint8Array = new Uint8Array(buffer);
      term.write(uint8Array);
    })
    ws.addEventListener('close', ev => {
      setClosed(true);
    })
    ws.addEventListener('error', ev => {
      setClosed(true);
    })
  }, [ref, sessionId, setOpen, setClosed]);

  return (
    <section>
      <header>
        <h1><Link to={`/devices/${deviceId}`}>{ deviceId ?? "???"}</Link></h1>
      </header>
      <main>
        <div ref={ref}></div>
        <div>State: {!open ? "opening" : (!closed ? "open" : "closed")}</div>
      </main>
    </section>
  );
}

export default Session;
