import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import Device from './Device';
import Devices from './Devices';
import Session from './Session';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route } from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App/>}>
          <Route index element={<Devices/>}/>
          <Route path="devices">
            <Route index element={<Devices/>}/>
            <Route path=":deviceId">
              <Route index element={<Device/>}/>
              <Route path=":sessionId" element={<Session/>}/>
            </Route>
          </Route>
          <Route path="*" element={<main>404 not found</main>}/>
        </Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
