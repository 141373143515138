import React from 'react';
import { Link, Outlet } from 'react-router-dom';
import './App.css';

const App: React.FC<{}> = () => {
  return (
    <div>
      <header><Link to="/">connectbot</Link></header>
      <Outlet />
    </div>
  );
}

export default App;
